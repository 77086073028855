import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useCurrency} from '../../hooks/use-currency'
import Icon from '../icon'
import Tooltip from '../tooltip'

const PriceLoyalty = ({priceClass, priceLoyalty, assets}) => {
	const intl = useIntl()
	const {currency} = useCurrency()

	const isOrderInfo = priceClass === 'cart' || priceClass === 'set'
	const hideTooltips = priceClass === 'suggestion' || priceClass === 'minicart' || isOrderInfo
	const {loyaltyMessage, loyaltyTooltip} = assets

	return (
		<div className={'price-' + priceClass + '-loyalty'}>
			<span className={'price-' + priceClass + '-loyalty__value'}>
				{intl.formatNumber(priceLoyalty, {
					style: 'currency',
					currency: currency
				})}
			</span>

			<span className={'price-' + priceClass + '-loyalty__msg'}>
				{loyaltyMessage}
				{loyaltyTooltip && !hideTooltips && (
					<Tooltip
						triggerClass={'tooltip-trigger--info tooltip-trigger--pricing'}
						tooltipClass={'tooltip--pricing'}
						triggerContent={<Icon id={'icon-info'} />}
						arrow={false}
						place={'top'}
						spacing={4}
						clickOnly={true}
					>
						{loyaltyTooltip}
					</Tooltip>
				)}
			</span>
		</div>
	)
}

PriceLoyalty.propTypes = {
	priceClass: PropTypes.string,
	priceLoyalty: PropTypes.number,
	assets: PropTypes.object
}

export default PriceLoyalty
