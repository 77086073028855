import React from 'react'
import PropTypes from 'prop-types'
import {useUserState} from '../../hooks/use-user-state'
import PriceSales from './pricesales'
import OmnibusPricing from './omnibuspricing'
import PriceLoyalty from './priceloyalty'

const Price = (props) => {
	const {price, priceClass, withLoyaltyPrice} = props
	const isAuthenticated = useUserState()

	if (!price) {
		return
	}

	const {
		OmnibusPrice,
		LoyaltyPrice,
		SalesPrice,
		StandardPrice,
		onPromotion,
		retailPriceDisplayFlag,
		assets
	} = price
	const displayFormattedPrice =
		priceClass === 'tile' || priceClass === 'tile-slider' || priceClass === 'product'
	const hasLoyaltyPrice = withLoyaltyPrice !== false && LoyaltyPrice > 0

	//Display loyalty separately for not logged in customers as additional information underneath
	const isAdditionalLoyaltyInfoDisplayed = hasLoyaltyPrice && !isAuthenticated
	const hideStandardPrice = isAdditionalLoyaltyInfoDisplayed || retailPriceDisplayFlag
	const hideAdditionalPriceInfo = priceClass === 'package'
	const showOmnibus = onPromotion || hasLoyaltyPrice

	return (
		<React.Fragment>
			<div className={'price-' + priceClass + '__wrapper'}>
				<PriceSales
					priceClass={priceClass}
					isPromoPrice={onPromotion}
					SalesPrice={SalesPrice}
					displayFormattedPrice={displayFormattedPrice}
				/>
			</div>

			{!hideAdditionalPriceInfo && (
				<div className={'price-' + priceClass + '__footer'}>
					{isAdditionalLoyaltyInfoDisplayed && (
						<PriceLoyalty
							priceClass={priceClass}
							priceLoyalty={LoyaltyPrice}
							assets={assets}
						/>
					)}

					{showOmnibus && (
						<OmnibusPricing
							priceClass={priceClass}
							omnibusPrice={OmnibusPrice}
							standardPrice={StandardPrice}
							hideStandardPrice={hideStandardPrice}
							retailPriceDisplayFlag={retailPriceDisplayFlag}
							assets={assets}
						/>
					)}
				</div>
			)}
		</React.Fragment>
	)
}

Price.propTypes = {
	price: PropTypes.object,
	priceClass: PropTypes.string,
	withLoyaltyPrice: PropTypes.bool
}

export default Price
