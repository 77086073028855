import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useCurrency} from '../../hooks/use-currency'
import Icon from '../icon'
import Tooltip from '../tooltip'

const OmnibusPricing = ({
	priceClass,
	hideStandardPrice,
	standardPrice,
	omnibusPrice,
	assets,
	retailPriceDisplayFlag
}) => {
	const intl = useIntl()
	const {currency} = useCurrency()
	const isOrderInfo = priceClass === 'cart' || priceClass === 'set'
	const hideTooltips = priceClass === 'suggestion' || priceClass === 'minicart' || isOrderInfo
	const classModifier = !retailPriceDisplayFlag ? ' price-omnibus-text__value--strike' : ''

	const {
		omnibusAssetLowest,
		omnibusAssetRegular,
		omnibusAssetRegularTooltip,
		omnibusAssetLowestTooltip
	} = assets

	return (
		<div className={'price-omnibus-text price-omnibus-text--' + priceClass}>
			{standardPrice && !hideStandardPrice && (
				<div className="price-omnibus-text__wrapper">
					<span
						className={
							'price-omnibus-text__value price-omnibus-text__value--regular' +
							classModifier
						}
					>
						{intl.formatNumber(standardPrice, {
							style: 'currency',
							currency: currency
						})}
					</span>
					<span className="price-omnibus-text__copy-text price-omnibus-text__copy-text--regular">
						{omnibusAssetRegular}
					</span>

					{omnibusAssetRegularTooltip && !hideTooltips && (
						<Tooltip
							triggerClass={'tooltip-trigger--info tooltip-trigger--pricing'}
							tooltipClass={'tooltip--pricing'}
							triggerContent={<Icon id={'icon-info'} />}
							arrow={false}
							place={'top'}
							spacing={4}
							clickOnly={true}
						>
							{omnibusAssetRegularTooltip}
						</Tooltip>
					)}
				</div>
			)}

			{omnibusAssetLowest && omnibusPrice && (
				<div className="price-omnibus-text__wrapper">
					<span className={'price-omnibus-text__value ' + classModifier}>
						{intl.formatNumber(omnibusPrice, {
							style: 'currency',
							currency: currency
						})}
					</span>
					<span className="price-omnibus-text__copy-text">{omnibusAssetLowest}</span>
					{omnibusAssetLowestTooltip && !hideTooltips && (
						<Tooltip
							triggerClass={'tooltip-trigger--info tooltip-trigger--pricing'}
							tooltipClass={'tooltip--pricing'}
							triggerContent={<Icon id={'icon-info'} />}
							arrow={false}
							place={'top'}
							spacing={4}
							clickOnly={true}
						>
							{omnibusAssetLowestTooltip}
						</Tooltip>
					)}
				</div>
			)}
		</div>
	)
}

OmnibusPricing.propTypes = {
	standardPrice: PropTypes.number,
	priceClass: PropTypes.string,
	omnibusPrice: PropTypes.number,
	hideStandardPrice: PropTypes.bool,
	retailPriceDisplayFlag: PropTypes.bool,
	assets: PropTypes.object
}

export default OmnibusPricing
